<template>
  <div>
    <i-header :title="'消息详情页'" @back-event="toBack"></i-header>
    <div class="messageContent">
      <div>
        <span class="messageTitles">{{ messageDetail.title }}</span>
        <span class="messageDate" v-if="messageDetail.sendTime">{{
          messageDetail.sendTime.replace("T", " ")
        }}</span>
      </div>
      <div class="messageBody">尊敬的用户,{{ messageDetail.body }}</div>
    </div>
    <i-skeleton v-if="isFirst" :row="8" />
  </div>
</template>

<script>

export default {
  name: "MessageDetail",
  components: {
  },
  data() {
    return {
      isFirst: true,
    };
  },
  computed: {
    messageDetail() {
      return this.$store.state.messageDetail;
    },
  },
  created() {
    this.messageRead();
  },
  methods: {
    messageRead() {
      let params = {
        logId: this.messageDetail.logId,
        readStatus: 1,
        tenantId: this.tenantId,
        isReadAll: false,
      };
      this.$service.Read(params).then((res) => {
        this.isFirst = false;
        if (!res.success) {
          this.$itoast.fail(res.error || res.msg);
        }
      });
    },
    toBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="less">
.messageContent {
  background: #fff;
  margin-bottom: 0.2rem;
  padding: 0rem 0.18rem;
  color: #999;
  .messageTitles {
    font-size: 0.16rem;
    color: #13161b;
  }
  .messageDate {
    float: right;
    font-size: 0.12rem;
    margin-top: 0.05rem;
  }
  .messageBody {
    padding: 0.06rem 0;
  }
}
</style>
