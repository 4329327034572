var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("i-header", {
        attrs: { title: "消息详情页" },
        on: { "back-event": _vm.toBack },
      }),
      _c("div", { staticClass: "messageContent" }, [
        _c("div", [
          _c("span", { staticClass: "messageTitles" }, [
            _vm._v(_vm._s(_vm.messageDetail.title)),
          ]),
          _vm.messageDetail.sendTime
            ? _c("span", { staticClass: "messageDate" }, [
                _vm._v(_vm._s(_vm.messageDetail.sendTime.replace("T", " "))),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "messageBody" }, [
          _vm._v("尊敬的用户," + _vm._s(_vm.messageDetail.body)),
        ]),
      ]),
      _vm.isFirst ? _c("i-skeleton", { attrs: { row: 8 } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }